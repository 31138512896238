export const transitions = {
  INACTIVE: {
    STAKE_PRESSED: "STAKING"
  },
  STAKING: {
    CANCEL_PRESSED: "INACTIVE",
    STAKE_CONFIRMED: "ACTIVE",
    STAKE_APPROVED: "STAKING"
  },
  ACTIVE: {
    ADJUST_PRESSED: "ADJUSTING",
    CLAIM_REWARD_CONFIRMED: "ACTIVE",
    UNSTAKE_AND_CLAIM_CONFIRMED: "INACTIVE"
  },
  ADJUSTING: {
    CANCEL_PRESSED: "ACTIVE",
    STAKE_CONFIRMED: "ACTIVE",
    STAKE_APPROVED: "ADJUSTING",
    UNSTAKE_CONFIRMED: "ACTIVE"
  },
  DISABLED: {
    CLAIM_REWARD_CONFIRMED: "DISABLED",
    UNSTAKE_AND_CLAIM_CONFIRMED: "DISABLED"
  }
};
